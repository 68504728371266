input::placeholder{
    color: #B1B1C5F9;
}

.submit-div {
    background-color: #005CA9;
    color: #fff;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center; 
  }
  

  .required-text {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .required-text p {
    color: #005CA9;
    font-size: 12px;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 20px; 
  }
    .loader {
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-left-color: #3498db;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: spin 1s linear infinite;
    }
  
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }