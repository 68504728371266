
.SortButtons {
    background-color: #005ca9;
    border: 1px solid;
    border-color: #b1b1c5;
    display: flex;
    gap: 20px;
    padding: 3px 24px 3px 24px;
    cursor: pointer;
}

.SortButtons .text-wrapper {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 24.5px;
    margin-right: -6.65px;
}

.SortButtons .vector {
    height: 23.25px;
    width: 23.25px;
}