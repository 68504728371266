body, html {
  margin: 0;
  font-family: 'Blender-Leicht', fallback-font, sans-serif !important;
}

code {
  font-family: 'Blender-Leicht';
}

#LoadingPage{

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: white;
    padding: 10%;

    $offset: 187;
      $duration: 1.4s;
    
      .spinner {
        animation: rotator $duration linear infinite;
      }
    
      @keyframes rotator {
        0% {
          transform: rotate(0deg);
        }
    
        100% {
          transform: rotate(270deg);
        }
      }
    
      .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation:
          dash $duration ease-in-out infinite,
          colors ($duration*4) ease-in-out infinite;
      }
    
      @keyframes colors {
        0% {
          stroke: #4285F4;
        }
    
        25% {
          stroke: #DE3E35;
        }
    
        50% {
          stroke: #F7C223;
        }
    
        75% {
          stroke: #1B9A59;
        }
    
        100% {
          stroke: #4285F4;
        }
      }
    
      @keyframes dash {
        0% {
          stroke-dashoffset: $offset;
        }
    
        50% {
          stroke-dashoffset: $offset/4;
          transform: rotate(135deg);
        }
    
        100% {
          stroke-dashoffset: $offset;
          transform: rotate(450deg);
        }
      }

}