input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
.inputCenter{
text-align: center;
    margin: 0;
    -moz-appearance:textfield;

}

.configurator-table{
}
.description{
  width: 657px;
  float: left;
  display: flex;
}
.descriptionP{
  width: 600px;
  position: absolute;
}
.keyPanelContent{
  width: 250px;
  rotate: 270deg;
  display: flex;
  justify-content: space-between;
  margin-top: 155px;
  margin-left: -105px;
}
.configurator-container h1{
  font-weight: 800;

}
.buttonA{
    cursor: pointer;
}
.buttonB{
  cursor: pointer;
}

.doorPanel{
  display: flex;
  width: 619px;
  height: 40px;
  background-color: #F2F2F2;
  align-self: flex-end;
  flex-direction: row;
  align-items: center;
}
.doorPanel > p, .doorPanel > div> p{
  font-size: 14px;
}
.doorPanel > div > div > p{
  font-size: 10px;
}
.doorPanel > div > div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.keyPanel{
  width: 37px;
  height: 302px;
  background-color: #F2F2F2;
}
.door-table{
  display: flex;
  flex-direction: column;
  float: left;
}
.door-table-row{
  width: 655px;
  height: 42px;
  display: flex;
  background-color: #F3F3F3;
  border: #B1B1C5 solid 1px;
  margin: 1px;
  align-items: center;
}

.door-table-row > input, .door-table-row select{
  height: 29px;
}

.door-table-row > input[type=checkbox]{
  margin: 2%;
}

.door-table-row > input[type=text]{
  width: 150px;
  border: none;
  background-color: #F3F3F3;
  border-bottom: #B1B1C5 solid 1px;
}

.door-table-row>input[type=number]{
  width: 30px;
  border: none;
  background-color: #F3F3F3;
  border-bottom: #B1B1C5 solid 1px;
}

.door-table-row > select{
  width: 145px;
  border: none;
  background-color: #F3F3F3;
  border-bottom: #B1B1C5 solid 1px;
}

.key-table{
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
}

.key-table-row{
  width: 42px;
    height: 302px;
    display: flex;
    background-color: #F3F3F3;
    border: #B1B1C5 solid 1px;
    margin: 0 1px 1px;
    align-items: center;
    flex-direction: column;
}

.key-table-row>input[type=checkbox] {
  margin-top: 10px;
  width: 12px;
}

.key-table-row>input[type=number] {
  width: 22px;
  border: none;
  background-color: #F3F3F3;
  border-bottom: #B1B1C5 solid 1px;
  appearance: none;
  -moz-appearance: textfield;
  /* -webkit-appearance: none; */
  margin-top: 50px;
}

.key-table-row>input[type=text]{
  width: 100px;
  rotate: 270deg;
  border: none;
  background-color: #F3F3F3;
  border-bottom: #B1B1C5 solid 1px;
  margin-top: 130px;
}

.permission-table{
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: hidden;
}

.permission-table-row{
  display: flex;
}

.permission-table-column{
  display: flex;
  flex-flow: wrap;
  min-width: 42px;
  min-height: 42px;
  background-color: #F3F3F3;
  margin: 1px;
  cursor: pointer;
  align-content: center;
  justify-content: center;
  font-size: x-large;
  font-weight: 100;
}
.LockConfiguratorButons{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.LockConfiguratorButon{
  all: unset;
  align-items: center;
  background-color: #005ca9;
  border: 1px solid;
  border-color: #b1b1c5;
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 7px 84px 9px 83.56px;
  position: relative;
  width: 238px;
  cursor: pointer;
  margin: 3% 0 3% 0;

}
.text-wrapper1{
  color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24.5px;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    
}
.LockConfiguratorButon .text-wrapper {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24.5px;
  margin-left: -51.9px;
  margin-right: -6.65px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
.LockConfiguratorButon .vector {
  height: 23.25px;
  margin-right: -49.91px;
  position: relative;
  width: 23.25px;
}
.inputValueChangerForm{
cursor: pointer;
}
.inputValueChangerForm div{
  width: 16px;
  height: 15px;
}

.ButtonAB{
  display: flex;
  justify-content: space-around;
}
.ButtonAB div {
      cursor: pointer;
}


input[type=number] {
    -moz-appearance:textfield;
}

.step-title{
  font-weight: bold;
  font-size: 14px;
}

.step-description{
  font-size: 14px;
}